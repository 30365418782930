import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Video } from './ui';
import { Slideshow } from './Slideshow/Slideshow';

const StyledMobileProductGallery = styled.section`
  ${minBreakpointQuery.tsmall`
    display: none;
  `}
`;

const StyledProductGallery = styled.section`
  display: none;

  ${minBreakpointQuery.tsmall`
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledVideo = styled(Video)`
  height: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  height: 100%;
`;

const ProductGallery = ({ media, ...props }) => {
  function renderMedia(
    { mediaContentType, originalSource, preview: { image } },
    id
  ) {
    return mediaContentType === 'VIDEO' ? (
      <StyledVideo
        key={id}
        data={{
          format: 'mp4',
          video: {
            mp4Url: originalSource.url,
          },
        }}
        controls={false}
        autoPlay={true}
        playOnHover={true}
        loop={true}
      />
    ) : (
      <StyledImage key={id} image={image.gatsbyImageData} alt={image.alt} />
    );
  }

  return (
    <>
      <StyledMobileProductGallery {...props}>
        <Slideshow items={media} renderItem={renderMedia} aspectRatio={0.75} />
      </StyledMobileProductGallery>
      <StyledProductGallery {...props}>
        {media.map(renderMedia)}
      </StyledProductGallery>
    </>
  );
};

export default ProductGallery;
