import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, Heading, Slider, Svg } from './ui';
import trustpilotIcon from '../images/trustpilot-icon.inline.svg';
import starIcon from '../images/star-icon.inline.svg';
import { BodySmall, Typography } from './typography/Typography';

const StyledReviews = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: 290px calc(100% - 360px);
    align-items: center;
    column-gap: 70px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 320px calc(100% - 420px);
    column-gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    grid-template-columns: 350px calc(100% - 480px);
    column-gap: 130px;
  `}
`;

const StyledIntro = styled.header`
  ${maxBreakpointQuery.tsmall`
    max-width: 300px;
  `}

  ${maxBreakpointQuery.smedium`
    max-width: 350px;
  `}
`;

const StyledTrustpilot = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 18px;

  ${minBreakpointQuery.medium`
    margin-top: 22px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 28px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 34px;
  `}
`;

const StyledTrustpilotText = styled(BodySmall)``;

const StyledIcon = styled(Svg)`
  width: 84px;
`;

const StyledItemsWrapper = styled.div`
  position: relative;
`;

const StyledItems = styled(Slider)`
  overflow: visible;
  clip-path: inset(0 -100vw 0 0);
  cursor: grab;

  ${maxBreakpointQuery.smedium`
    margin-top: 55px;
  `}

  .swiper-slide {
    width: auto;
  }
`;

const StyledItem = styled.figure`
  margin: 0;
`;

const StyledStars = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledHeading = styled(props => (
  <Typography variant="H6" as="h3" {...props} />
))`
  text-transform: uppercase;
  margin-top: 24px;

  ${minBreakpointQuery.medium`
    margin-top: 28px;
  `}
`;

const StyledNameDate = styled(props => (
  <Typography variant="BodySmall" as="figcaption" {...props} />
))`
  margin-top: 12px;
  opacity: 0.5;

  ${minBreakpointQuery.medium`
    margin-top: 16px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 20px;

  `}
`;

const StyledQuote = styled.blockquote`
  margin: 16px 0 0;

  ${minBreakpointQuery.medium`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 24px;
  `}
`;

const StyledText = styled(BodySmall)``;

const Reviews = ({ heading, items }) => {
  const sliderOptions = {
    slidesPerView: 1.4,
    spaceBetween: 30,
    breakpoints: {
      [breakpointSizes.tiny]: {
        slidesPerView: 1.6,
        spaceBetween: 50,
      },
      [breakpointSizes.small]: {
        slidesPerView: 2.4,
        spaceBetween: 70,
      },
      [breakpointSizes.smedium]: {
        slidesPerView: 1.4,
        spaceBetween: 50,
      },
      [breakpointSizes.medium]: {
        slidesPerView: 1.6,
        spaceBetween: 70,
      },
      [breakpointSizes.large]: {
        slidesPerView: 2.4,
        spaceBetween: 100,
      },
      [breakpointSizes.xxlarge]: {
        slidesPerView: 2.6,
        spaceBetween: 130,
      },
    },
  };

  return (
    items &&
    items.length > 0 && (
      <StyledReviews>
        <Container wide={true}>
          <StyledInner>
            <StyledIntro>
              <Heading>{heading || 'Latest Reviews'}</Heading>
              <StyledTrustpilot>
                <StyledTrustpilotText>Reviews from</StyledTrustpilotText>
                <StyledIcon image={trustpilotIcon} />
              </StyledTrustpilot>
            </StyledIntro>
            <StyledItemsWrapper>
              <StyledItems {...sliderOptions}>
                {items.map(({ rating, heading, author, date, quote }, id) => (
                  <StyledItem key={id}>
                    <StyledStars>
                      {[...Array(rating)].map(() => (
                        <Svg image={starIcon} />
                      ))}
                    </StyledStars>
                    <StyledHeading>{heading}</StyledHeading>
                    <StyledNameDate>
                      {author}
                      {date && ` - ${date}`}
                    </StyledNameDate>
                    <StyledQuote>
                      <StyledText>{quote}</StyledText>
                    </StyledQuote>
                  </StyledItem>
                ))}
              </StyledItems>
            </StyledItemsWrapper>
          </StyledInner>
        </Container>
      </StyledReviews>
    )
  );
};

export default Reviews;
