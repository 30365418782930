import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fluidFontSize,
  zIndexLayers,
  visuallyHidden,
  standardTransition,
} from '../styles';
import { Container, AnimatedWords, AnimatedBlock } from './ui';
import ProductPrice from './ProductPrice';
import ProductAddToCart from './ProductAddToCart';
import { BodyLarge, BodySmall, H1, Typography } from './typography/Typography';
import { motion } from 'framer-motion';

const StyledProductSummary = styled.section``;

const StyledHeading = styled(H1)`
  text-transform: uppercase;
`;

const StyledPrice = styled(ProductPrice)`
  margin-top: 34px;
  ${fluidFontSize(
    '16px',
    '24px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};

  ${minBreakpointQuery.small`
    margin-top: 38px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 42px;
  `}
`;

const StyledDescription = styled(BodyLarge)`
  margin-top: 16px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 28px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 32px;
  `}
`;

const StyledAddToCartBlock = styled.div`
  ${maxBreakpointQuery.medium`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 12px 30px;
    background-color: ${brandColours.quinary};
    z-index: ${zIndexLayers.second};
  `}

  ${maxBreakpointQuery.smedium`
    padding-right: 25px;
    padding-left: 25px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 40px;
  `}
`;

const StyledAddToCartStrip = styled.div`
  ${maxBreakpointQuery.medium`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.medium`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndexLayers.second};
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${standardColours.white};
    background-color: ${standardColours.black};
    transform: translateY(100%);
    transition: ${standardTransition('transform')};
  `}

  ${({ display }) => {
    if (display) {
      return css`
        ${minBreakpointQuery.medium`
          transform: translateY(0);
        `}
      `;
    }
  }};
`;

const StyledAddToCartStripInner = styled.div`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    gap: 32px;
  `}
`;

const StyledNamePrice = styled(props => (
  <Typography as="p" variant="eyebrow" {...props} />
))`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ isStrip }) => {
    if (!isStrip) {
      return css`
        margin-bottom: 12px;

        ${minBreakpointQuery.medium`
          ${visuallyHidden()};
        `}
      `;
    }
  }}
`;

const StyledTastingNoteWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledTastingNoteImage = styled.img`
  width: auto;
  height: 75px;
  max-width: 75px;
`;

const StyledTastingNoteLabel = styled(BodySmall)`
  color: ${standardColours.black};
  text-align: center;
`;

const StyledName = styled.span`
  margin-right: 3px;
`;

const AddToCart = ({ name, priceRange, variants, isStrip, id }) => (
  <>
    <StyledNamePrice isStrip={isStrip}>
      <StyledName>{name} -</StyledName>
      <ProductPrice priceRange={priceRange} variants={variants} />
    </StyledNamePrice>
    <ProductAddToCart
      name={name}
      priceRange={priceRange}
      variants={variants}
      isProductSummaryStrip={isStrip}
      id={id}
    />
  </>
);

const StyledTastingNotesWrapper = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
`;

const ProductSummary = ({
  heading,
  priceRange,
  variants,
  description,
  tastingNotes,
  id,
  ...props
}) => {
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    setTimeout(() => setLoaded(true), '100');
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const tastingNotesVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },
  };

  const itemVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  };

  return (
    <StyledProductSummary {...props}>
      <StyledHeading>
        <AnimatedWords>{heading}</AnimatedWords>
      </StyledHeading>
      <StyledTastingNotesWrapper
        variants={tastingNotesVariants}
        initial="hidden"
        animate="visible"
      >
        {tastingNotes.map(tastingNote => {
          return (
            <StyledTastingNoteWrapper variants={itemVariants}>
              <StyledTastingNoteImage
                src={tastingNote.image?.url}
                alt={tastingNote.image?.alt}
              />
              <StyledTastingNoteLabel>
                {tastingNote.title}
              </StyledTastingNoteLabel>
            </StyledTastingNoteWrapper>
          );
        })}
      </StyledTastingNotesWrapper>
      <AnimatedBlock>
        <StyledPrice priceRange={priceRange} variants={variants} />
        {description && <StyledDescription>{description}</StyledDescription>}
      </AnimatedBlock>
      <StyledAddToCartBlock ref={ref}>
        <AnimatedBlock>
          <AddToCart
            name={heading}
            priceRange={priceRange}
            variants={variants}
            id={id}
          />
        </AnimatedBlock>
      </StyledAddToCartBlock>
      <StyledAddToCartStrip display={loaded && !inView}>
        <Container narrower={true}>
          <StyledAddToCartStripInner>
            <AddToCart
              name={heading}
              priceRange={priceRange}
              variants={variants}
              isStrip={true}
            />
          </StyledAddToCartStripInner>
        </Container>
      </StyledAddToCartStrip>
    </StyledProductSummary>
  );
};

export default ProductSummary;
