import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container } from './ui';
import ProductSummary from './ProductSummary';
import ProductGallery from './ProductGallery';
import ProductInfo from './ProductInfo';

const StyledProductDetail = styled.section``;

const StyledContainer = styled(Container)`
  ${minBreakpointQuery.medium`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledInner = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1.2fr 1fr;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 1.2fr 1fr;
  `}
`;

const commonSummaryInfoStyles = () => {
  return css`
    ${minBreakpointQuery.medium`
      margin-right: auto;
      margin-left: auto;
      padding-right: 30px;
      padding-left: 30px;
      width: 100%;
      max-width: 600px;
    `}
  `;
};

const StyledSummary = styled(ProductSummary)`
  ${commonSummaryInfoStyles()};
  margin: 60px 0 40px;

  ${minBreakpointQuery.medium`
    align-self: center;
    margin-top: 40px;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 50px;
  `}
`;

const StyledGallery = styled(ProductGallery)`
  ${minBreakpointQuery.medium`
    grid-area: 1/1/3/2;
  `}
`;

const StyledInfo = styled(ProductInfo)`
  ${commonSummaryInfoStyles()};
  margin: 60px 0;

  ${minBreakpointQuery.medium`
    align-self: start;
    margin-top: 30px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 50px;
  `}
`;

const ProductDetail = ({
  heading,
  priceRange,
  variants,
  description,
  media,
  iconUsps,
  tastingNotes,
  id,
}) => {
  useEffect(() => {
    window._learnq.push([
      'track',
      'Viewed Product',
      {
        productTitle: heading,
        productPriceRange: priceRange,
        productVariants: variants,
        productDescription: description,
        productMedia: media,
        productId: id,
      },
    ]);
  }, []);
  return (
    <StyledProductDetail>
      <StyledContainer wide={true}>
        <StyledInner>
          <StyledSummary
            heading={heading}
            priceRange={priceRange}
            variants={variants}
            description={description}
            tastingNotes={tastingNotes}
            id={id}
          />
          <StyledGallery media={media} />
          <StyledInfo iconUsps={iconUsps} />
        </StyledInner>
      </StyledContainer>
    </StyledProductDetail>
  );
};

export default ProductDetail;
