import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  standardTransition,
} from '../styles';
import { Container, Heading, AnimatedText, Svg } from './ui';
import Badge from './Badge';
import bottleIcon from '../images/bottle-icon.inline.svg';
import { BodyLarge, H2 } from './typography/Typography';

const StyledProductContent = styled.section`
  ${sectionPaddings('1px')};
  color: ${standardColours.white};
  background-color: ${brandColours.tertiary};
`;

const StyledContentImages = styled.div`
  margin: 75px 0;

  ${minBreakpointQuery.smedium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    align-items: start;
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 80px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 100px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 120px;
  `}
`;

const StyledImages = styled.div`
  display: flex;
  align-items: start;
  margin: 0 15px;

  ${minBreakpointQuery.smedium`
    grid-row: 2/3;
    margin-right: 10px;
    margin-left: -10px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-right: 20px;
    margin-left: -20px;
  `} 

  ${minBreakpointQuery.xxlarge`
    margin-right: 30px;
    margin-left: -30px;
  `}  

  ${minBreakpointQuery.xxxlarge`
    margin-right: 40px;
    margin-left: -40px;
  `}
`;

const StyledMediumImage = styled(GatsbyImage)`
  margin-top: 40px;
`;

const StyledSmallImage = styled(GatsbyImage)`
  margin-left: -30px;
`;

const StyledContent = styled.div`
  ${maxBreakpointQuery.smedium`
    margin-top: 70px;
    margin-bottom: 70px;
  `}

  ${minBreakpointQuery.smedium`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledHeading = styled(props => <Heading {...props} variant="H2" />)`
  margin-bottom: 16px;
  text-transform: uppercase;

  ${minBreakpointQuery.small`
      margin-bottom: 20px;
    `}

  ${minBreakpointQuery.large`
      margin-bottom: 24px;
    `};
`;

const StyledText = styled(BodyLarge)`
  white-space: break-spaces;
`;

const StyledLargeImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${maxBreakpointQuery.smedium`
    margin-right: 15px;
    margin-left: 15px;
  `}

  ${minBreakpointQuery.smedium`
    grid-row: 1/3;
    grid-column: 2/3;
  `}
`;

const StyledLargeImage = styled(GatsbyImage)`
  ${maxBreakpointQuery.smedium`
    max-height: 800px;
  `}
`;

const StyledBadge = styled(Badge)`
  margin-top: -30px;
  margin-right: 40px;
  align-self: end;

  ${minBreakpointQuery.small`
    margin-top: -35px;
    margin-right: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-top: -40px;
    margin-right: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-right: 70px;
  `}
`;

const StyledBottleContent = styled.div`
  position: relative;
  margin: 120px auto;
  padding: 160px 0 120px;
  max-width: 660px;
  text-align: center;

  ${minBreakpointQuery.small`
    padding-top: 200px;
  `}

  ${minBreakpointQuery.large`
    padding-top: 240px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 280px;
  `}
`;

const StyledBottleIcon = styled(Svg)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;

  ${({ inView }) => {
    if (inView) {
      return css`
        animation: ${standardTransition('draw', '12')} both;
      `;
    }
  }};

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const StyledSubheading = styled(H2)`
  margin-bottom: 16px;
  text-transform: uppercase;

  ${minBreakpointQuery.small`
      margin-bottom: 20px;
    `}

  ${minBreakpointQuery.large`
      margin-bottom: 24px;
    `};
`;

const ProductContent = ({
  heading,
  text,
  smallImage,
  mediumImage,
  largeImage,
  bottleHeading,
  bottleText,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <StyledProductContent>
      <Container>
        {heading && text && largeImage && (
          <StyledContentImages>
            {mediumImage && smallImage && (
              <StyledImages>
                <Parallax speed={4}>
                  <StyledMediumImage
                    image={mediumImage.gatsbyImageData}
                    alt={mediumImage.alt}
                  />
                </Parallax>
                <Parallax speed={-4}>
                  <StyledSmallImage
                    image={smallImage.gatsbyImageData}
                    alt={smallImage.alt}
                  />
                </Parallax>
              </StyledImages>
            )}
            <StyledContent>
              <StyledHeading>{heading}</StyledHeading>
              <StyledText>
                <AnimatedText>{text}</AnimatedText>
              </StyledText>
            </StyledContent>
            <StyledLargeImageWrapper>
              <Parallax speed={2}>
                <StyledLargeImage
                  image={largeImage.gatsbyImageData}
                  alt={largeImage.alt}
                />
              </Parallax>
              <StyledBadge />
            </StyledLargeImageWrapper>
          </StyledContentImages>
        )}
        <StyledBottleContent ref={ref}>
          <StyledBottleIcon image={bottleIcon} inView={inView} />
          <StyledSubheading>
            <AnimatedText>{bottleHeading}</AnimatedText>
          </StyledSubheading>
          <StyledText>
            <AnimatedText>{bottleText}</AnimatedText>
          </StyledText>
        </StyledBottleContent>
      </Container>
    </StyledProductContent>
  );
};

export default ProductContent;
