import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import ProductDetail from '../components/ProductDetail';
import IconUsps from '../components/IconUsps';
import ProductContent from '../components/ProductContent';
import Reviews from '../components/Reviews';
import ModularBlocks from '../components/ModularBlocks';

const ProductDetailPageTemplate = ({
  data: {
    shopifyProduct: {
      storefrontId,
      seo,
      title,
      description,
      priceRange,
      variants,
      media,
    },
    datoCmsProduct: {
      contentHeading,
      contentText,
      contentSmallImage,
      contentMediumImage,
      contentLargeImage,
      reviewsHeading,
      reviews,
      modularBlocks: productModularBlocks,
      tastingNotes,
    },
    datoCmsProductSitewide: {
      companyIconUsps,
      productIconUsps,
      bottleHeading,
      bottleText,
      modularBlocks: productSitewideModularBlocks,
    },
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms>
        <title>{`${seo.title || title}${titleSuffix}`}</title>
        <meta name="description" content={seo.description || description} />
      </HelmetDatoCms>
      <main>
        <ProductDetail
          heading={title}
          priceRange={priceRange}
          variants={variants}
          description={description}
          media={media}
          iconUsps={companyIconUsps}
          tastingNotes={tastingNotes}
          id={storefrontId}
        />
        <IconUsps items={productIconUsps} />
        <ProductContent
          heading={contentHeading}
          text={contentText}
          smallImage={contentSmallImage}
          mediumImage={contentMediumImage}
          largeImage={contentLargeImage}
          bottleHeading={bottleHeading}
          bottleText={bottleText}
        />
        <Reviews heading={reviewsHeading} items={reviews} />
        <ModularBlocks items={productModularBlocks} />
      </main>
      <ModularBlocks items={productSitewideModularBlocks} />
    </Layout>
  );
};

export const ProductDetailPageTemplateQuery = graphql`
  query ProductPageTemplateQuery($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      storefrontId
      seo {
        title
        description
      }
      title
      description
      priceRange: priceRangeV2 {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      variants {
        storefrontId
        price
        compareAtPrice
        inventoryQuantity
      }
      media {
        mediaContentType
        preview {
          image {
            gatsbyImageData(width: 500, height: 670)
            alt: altText
          }
        }
        ... on ShopifyVideo {
          originalSource {
            url
          }
        }
      }
    }
    datoCmsProduct(shopifyProduct: { eq: $handle }) {
      contentHeading
      contentText
      contentSmallImage {
        gatsbyImageData(width: 300, height: 410)
        alt
      }
      contentMediumImage {
        gatsbyImageData(width: 400, height: 510)
        alt
      }
      contentLargeImage {
        gatsbyImageData(width: 690, height: 920)
        alt
      }
      tastingNotes {
        title
        image {
          url
          alt
        }
      }
      reviewsHeading
      reviews {
        rating
        heading
        author
        date(formatString: "DD/MM/YYYY")
        quote
      }
      modularBlocks {
        ...ImageContentModularBlockFragment
        ...PageBreakModularBlockV1Fragment
      }
    }
    datoCmsProductSitewide {
      companyIconUsps {
        icon {
          url
          alt
        }
        text
      }
      productIconUsps {
        icon {
          url
          alt
        }
        text
      }
      bottleHeading
      bottleText
      modularBlocks {
        ...BrandyComingSoonModularBlockFragment
        ...InstagramFeedModularBlockFragment
      }
    }
    datoCmsSite {
      globalSeo {
        titleSuffix
      }
    }
  }
`;

export default ProductDetailPageTemplate;
